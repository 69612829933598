import React, { useCallback, useMemo, useState } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { FaYoutube } from 'react-icons/fa';

const IFrame = (props) => (
  <Box
    as="iframe"
    title="TimelessTime content iframe"
    my={8}
    maxWidth={['100%', '75%', '50%']}
    {...props}
  />
);

const Placeholder = ({ playVideo, embedId, src, ...props }) => (
  <Box
    my={8}
    maxWidth={['100%', '75%', '50%']}
    background={`url(https://i.ytimg.com/vi/${embedId}/hqdefault.jpg)`}
    onClick={playVideo}
    _hover={{
      cursor: 'pointer',
    }}
    {...props}
  >
    <Flex width="100%" height="100%" justify="center" align="center">
      <IconButton
        variant="unstyled"
        padding={0}
        margin={0}
        height="auto"
        width="auto"
        padding-inline-start={0}
        padding-inline-end={0}
        fontSize="6xl"
        color="orange"
        onClick={playVideo}
        aria-label="Play video"
        icon={<FaYoutube />}
      />
    </Flex>
  </Box>
);

const youtubeRegex = /.*youtube.*embed\/(?<embedId>[A-Za-z0-9]+)$/;

const calculateYoutubeEmbed = (src) => {
  try {
    const { groups } = src.match(youtubeRegex);
    if (groups.embedId) {
      return groups.embedId;
    }
  } catch {
    // ignore error, just render the fallback
  }
  return undefined;
};

const LazyIframe = ({ src, width, height, ...props }) => {
  const embedId = useMemo(() => calculateYoutubeEmbed(src), [src]);
  const [showPlaceholder, setShowPlaceholder] = useState(embedId !== undefined);
  const playVideo = useCallback(() => {
    setShowPlaceholder(false);
  });
  if (embedId) {
    if (showPlaceholder) {
      return (
        <Placeholder
          className="iframe-placeholder"
          embedId={embedId}
          playVideo={playVideo}
          width={width}
          height={height}
          {...props}
        />
      );
    }
    return (
      <IFrame
        src={`${src}?autoplay=1`}
        width={width}
        height={height}
        {...props}
      />
    );
  }

  return (
    <IFrame src={src} width={width} height={height} {...props} loading="lazy" />
  );
};

export default LazyIframe;
